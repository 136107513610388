@font-face {
  font-family: 'Red Hat Display';
  src: local('arial-light'),
    url('../assets/font/RedHatDisplay-Light.ttf') format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'Red Hat Display Italic';
  src: local('arial-light'),
    url('../assets/font/RedHatText-MediumItalic.ttf') format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'Red Hat Display Bold';
  src: local('Red Hat Display Bold'),
    url('../assets/font/RedHatDisplay-Bold.ttf') format('truetype');
  font-weight: bold;
}
@font-face {
  font-family: 'Red Hat Display Medium';
  src: local('Red Hat Display Medium'),
    url('../assets/font/RedHatDisplay-SemiBold.ttf') format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'Red Hat Display SemiBold';
  src: local('Red Hat Display SemiBold'),
    url('../assets/font/RedHatDisplay-SemiBold.ttf') format('truetype');
  font-weight: 600;
}
@font-face {
  font-family: 'Red Hat Display Regular';
  src: local('Red Hat Display Regular'),
    url('../assets/font/RedHatDisplay-Regular.ttf') format('truetype');
  font-weight: 400;
}
#Upload_Now {
  font-family: 'Arial-Regular';
}
body,
div,
span,
label,
input,
textarea,
button,
select,
option,
td,
th,
li,
p {
  font-family: Red Hat Display Regular;
}
body {
  font-size: 0.9em !important;
  background-color: #f8f8fa !important;
}

.upload-btn {
  position: relative;

  img {
    position: absolute;
    right: 12px;
    top: 15px;
  }
  label {
    font-family: 'Red Hat Display Medium' !important;
    color: #241a2e;
    font-size: 14px !important;
  }
}

.back-nav-link {
  display: flex;
  align-items: center;
  a {
    font-family: 'Red Hat Display Bold';
    color: #005ebf;
    font-size: 12px;
  }
}

.chip-status-green {
  background-color: #1d9900 !important;
  padding: 8px 2px !important;
  height: 24px !important;
  // position: absolute;
  // right: 0;
  // top: 21px;

  img {
    width: 10px;
    height: 10px;
    top: 0 !important;
  }

  span {
    font-family: 'Red Hat Display SemiBold';
    font-size: 12px;
    text-transform: uppercase;
  }
}

textarea {
  background-color: #f8f8fa !important;
  padding: 10px !important;
  height: 100px !important;
  border: 1px solid #e0e0e0 !important;
  border-radius: 8px;
  box-sizing: border-box !important;
  font-family: 'Red Hat Display SemiBold' !important;
  font-size: 14px !important;
  color: #292929 !important;

  &:focus-visible {
    border: 1px solid #e0e0e0 !important;
  }
  &:active {
    border: 1px solid #e0e0e0 !important;
  }
  &:hover {
    border: 1px solid #e0e0e0 !important;
  }
}
textarea,
input:focus {
  outline: none !important;
}

input {
  background-color: #f8f8fa !important;
  padding: 0 10px !important;
  height: 40px !important;
  border: 1px solid #e0e0e0 !important;
  border-radius: 8px;
  box-sizing: border-box !important;
  font-family: 'Red Hat Display SemiBold' !important;
  font-size: 16px !important;
  color: #292929 !important;

  &:hover {
    border: 1px solid #e0e0e0 !important;
  }
}

label {
  font-family: 'Red Hat Display bold';
  font-size: 14px;
  color: #292929;
  line-height: 30px;
  margin: 0px;
}

textarea,
input:focus {
  outline: none !important;
}

.MuiOutlinedInput-notchedOutline {
  border: 1px solid transparent !important;
}

.default-chexbox {
  .MuiCheckbox-colorPrimary {
    color: #e0e0e0;
  }

  .Mui-checked {
    color: #bf0000 !important;
  }

  span {
    font-family: 'Red Hat Display SemiBold';
    color: #292929;
    font-size: 14px;
    line-height: 18px;
  }
}

.liberty-loader {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999999;
  justify-content: center;
  align-items: center;
  // background: url('../assets/images/loading-spinner.gif') 50% 50% no-repeat
  //   rgb(0 0 0 / 32%);
}
.active > li {
  background-color: #2a5686 !important;
  &:hover {
    background-color: #2a5686 !important;
  }
}

.siderbg {
  background-color: red;
}

.sidebar-inner {
  img {
    margin: 30px 35px 35px 35px;
    display: table;
  }
}

.sidebar-menu {
  li {
    span {
      font-family: 'Red Hat Display Regular' !important;
      color: #ffffff !important;
      // opacity: 0.4;
      font-size: 15px;
    }
  }
}

.sidebar-toggle {
  button {
    margin: 0 auto;
    color: #ffffff;
    opacity: 0.4;
    position: relative;
    left: -5px;
  }
}

.login-area {
  bottom: 35px;
  width: 100%;
}

.custom-search {
  input {
    width: 295px !important;
    height: 48px !important;
    background-color: #f8f8fa !important;
    border: 1px solid #e0e0e0 !important;
    padding-left: calc(1em + 32px) !important;
    margin: 7px 0;
    font-size: 14px !important;
    font-family: Red Hat Display Regular !important;
    color: #979598 !important;
  }
}

.dropdown-btn {
  background: transparent !important;
  padding: 0 !important;
  width: auto;
}

.tableview {
  thead {
    th {
      padding: 5px;
    }
  }
  tbody {
    td {
      a {
        color: #005ebf !important;
        font-family: 'Red Hat Display Bold';
        font-size: 13px;
      }
    }
  }
}

.headernav {
  ul {
    display: flex;
    justify-content: center;
    padding: 11px 0px;

    li {
      list-style: none;
      margin: 0 0px;
      width: auto;

      a {
        color: #241a2e;
        text-decoration: none;
        font-size: 15px;
        font-family: 'Red Hat Display bold';
        text-transform: uppercase;
        position: relative;
      }
    }

    .active a {
      color: #db2426 !important;

      &:after {
        content: '';
        position: absolute;
        border-bottom: 4px solid #db2426;
        width: 100%;
        bottom: -22px;
        left: 0;
      }
    }
  }
}

ul.filterlist {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 11px 0px;

  li {
    button {
      font-family: Red Hat Display SemiBold;
      font-size: 14px;
      color: #241a2e;
      text-transform: capitalize;

      span {
        margin: 0 5px;
      }
    }
  }
}

.popwidget {
  z-index: 9999;
  min-width: 160px;

  .MuiPaper-root {
    background: #292929;
    border-radius: 3px;
    p {
      color: #fff;
      font-family: 'Red Hat Display SemiBold';
      font-size: 14px;
      padding: 1px 10px;
    }
  }
}
.popwidget1 {
  z-index: 9999;
  min-width: 160px;

  .MuiPaper-root {
    // background: #292929;
    border-radius: 3px;
    p {
      color: #fff;
      font-family: 'Red Hat Display SemiBold';
      font-size: 14px;
      padding: 0px;
    }
  }
}

.textArea {
  display: block;
  width: 100%;
  max-height: 45px;
  overflow-y: auto;
  box-sizing: border-box;
  border: 1px solid #ccc;
  white-space: pre-wrap;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  cursor: text;
  border-radius: 2%;
  resize: none;
  &:focus {
    outline: none;
  }
}

.shaded-cell {
  background-color: #f8f8fa;
}

.clickable-cell {
  cursor: pointer;
}

.ag-row-selected {
  .shaded-cell {
    background-color: transparent;
  }
}

.editmodal {
  .b-bottom {
    padding-bottom: 14px;
    border-bottom: 1px solid #e0e0e0;
    margin-bottom: 14px;
  }
  .c-bottom {
    padding-bottom: 14px;
    margin-bottom: 14px;
  }
  .note {
    color: #979598 !important;
    font-family: Red Hat Display Medium !important;
    font-size: 11px !important;
    line-height: 14px !important;
    margin-bottom: 14px;
  }
  .notesTitle {
    color: #292929 !important;
    font-family: Red Hat Display Medium !important;
    font-size: 12px !important;
    line-height: 14px !important;
    margin-bottom: 5px;
  }
  .title {
    color: #292929 !important;
    font-family: Red Hat Display Bold !important;
    font-size: 20px !important;
    letter-spacing: 2.5px;
    line-height: 26px !important;
    padding-bottom: 14px;
    border-bottom: 1px solid #e0e0e0;
    margin-bottom: 14px;
  }
  .p-text {
    color: #bf0000 !important;
    font-family: Red Hat Display SemiBold !important;
    font-size: 12px !important;
    margin-bottom: 5px;
  }
  .pro-name {
    color: #292929 !important;
    font-family: Red Hat Display Bold !important;
    font-size: 16px !important;
    line-height: 34px !important;
  }
  .input-box {
    height: 32px;
    width: 110px;
    border: 1px solid #d5d5d5;
    border-radius: 4px;
    background-color: #ffffff;
    text-align: center;
    color: #292929 !important;
    font-family: Red Hat Display Bold !important;
    font-size: 16px !important;
  }
}
.model-widget {
  h2 {
    font-family: 'Red Hat Display Bold';
    font-size: 20px !important;
    color: #292929;
    text-transform: uppercase;
    letter-spacing: 2px;
  }

  span {
    font-family: 'Red Hat Display bold';
    color: #333;
    font-size: 14px;
    // text-decoration: underline;
  }

  h4 {
    font-family: 'Red Hat Display SemiBold';
    font-size: 20px;
    line-height: 41px;
    color: #292929;
    margin: 10px 0 5px;

    span {
      color: #bf0000;
      font-family: 'Red Hat Display Bold';
    }
  }

  h6 {
    font-family: 'Red Hat Display SemiBold';
    font-size: 16px;
    color: #292929;
  }

  .model-timesheet {
    border: 1px solid #e0e0e0;
    margin: 15px 0;
    min-height: 200px;
    border-radius: 10px;
    padding: 30px;
  }

  label {
    font-family: 'Red Hat Display Regular';
    font-size: 12px;
    color: #241a2e;
  }

  input {
    border: 1px solid #f8f8fa !important;
    margin-bottom: 5px;
    font-family: 'Red Hat Display Medium' !important;
    font-size: 14px !important;
  }

  .MuiOutlinedInput-notchedOutline {
    border: 1px solid transparent;
  }

  .common-textarea {
    background: #f8f8fa;
    width: 100%;
    margin-bottom: 25px;
    border: 1px solid #d5d5d5;
    border-radius: 8px;

    .MuiInputBase-multiline {
      padding: 0;
    }

    textarea {
      font-family: 'Red Hat Display SemiBold' !important;
      font-size: 16px !important;
      color: #979598 !important;
      height: 76px !important;
      border: 0px solid transparent !important;
      margin: 0 !important;
    }
  }

  .common-select {
    display: block;
    background: #f8f8fa;
    border: 1px solid #f8f8fa !important;
    font-family: 'Red Hat Display Medium' !important;
    font-size: 14px !important;
    border-radius: 8px;
    height: 43px;
    width: 100%;

    .MuiInputBase-input {
      padding: 10px !important;
      padding-right: 0 !important;
    }

    .MuiMenu-list li {
      font-size: 10px;
    }
  }
}
.common-select {
  display: block;
  background: #f8f8fa;
  border: 1px solid #f8f8fa !important;
  font-family: 'Red Hat Display Medium' !important;
  font-size: 14px !important;
  border-radius: 8px;
  height: 43px;
  width: 100%;

  .MuiInputBase-input {
    padding: 10px !important;
    padding-right: 0 !important;
  }

  .MuiMenu-list li {
    font-size: 10px;
  }
}

.MuiMenu-list li {
  font-family: 'Red Hat Display Medium' !important;
  font-size: 14px !important;
}

.notes {
  font-size: 11px !important;
  font-family: 'Red Hat Display medium' !important ;
  color: #979598 !important;
  line-height: 14px !important;
  border-top: 1px solid #e0e0e0;
  padding: 17px 0 !important;
  margin: 22px 0 !important;
}
.pointer {
  cursor: pointer;
}
.model-btn {
  display: flex;
  justify-content: space-between;
  text-transform: capitalize;

  button {
    font-family: 'Red Hat Display Bold' !important;
  }

  .MuiButton-contained {
    background: #bf0000;
    padding: 10px 20px;
    border-radius: 2px !important;
    text-transform: capitalize !important;
    font-size: 15px !important;
    margin: 0 !important;
    width: auto;
    height: 46px;

    &:hover {
      background-color: #bf0000;
    }
  }

  .MuiButton-textPrimary {
    color: #292929;
    text-decoration: underline;
    font-size: 14px;
    text-transform: capitalize;
  }
}

.sideNavactive {
  border-left: 6px solid #bf0500 !important;
  background: #4e0103 !important;
  text-decoration: none !important;
}

.sidebarA:active {
  text-decoration: none !important;
}

a.sidebarA {
  text-decoration: none;
}

.plusAddButton {
  background: #005ebf !important;
  border-radius: 4px !important;
  background-color: #005ebf !important;
  box-shadow: 0 2px 4px 0 rgb(0 94 191 / 40%) !important;
  padding: 0 !important;
  padding-left: 17px !important;
  color: #ffffff !important;
  font-family: Red Hat Display Bold !important;
  font-size: 14px !important;
  text-transform: capitalize !important;
  .MuiButton-endIcon {
    border-left: 1px solid #003f80 !important;
    height: 40px !important;
    justify-content: center !important;
    align-items: center !important;
    padding: 8px !important;
    margin-right: 0px !important;
    margin-left: 35px !important;
  }
}
.admidSidebarDivider {
  border-color: #8c7173 !important;
}
.whiteText {
  color: #ffffff !important;
}
.commonpopover {
  p {
    background-color: #241a2e;
    filter: drop-shadow(-2px 0px 1px rgba(0, 0, 0, 0.5));
    min-width: 217px;
    max-width: 217px !important;
  }
  .MuiFormControlLabel-label {
    font-family: 'Red Hat Display Bold';
    font-size: 15px;
    color: #ffffff;
  }
  button {
    background-color: #5d5d5d;
    width: 100%;
    font-family: 'Red Hat Display Bold';
    font-size: 14px;
    border-radius: 4px;
    text-transform: capitalize;

    &:hover {
      background-color: #5d5d5d;
    }
  }

  .MuiInputBase-root {
    width: 100% !important;
    background: #ffffff;
    height: 32px;
    margin: 10px 0;
    font-family: 'Red Hat Display SemiBold';
    font-size: 14px;
  }
}

.commonpopover1 {
  p {
    background-color: #241a2e;
    filter: drop-shadow(-2px 0px 1px rgba(0, 0, 0, 0.5));
    min-width: 217px;
    max-width: 217px !important;
  }
  .MuiFormControlLabel-label {
    font-family: 'Red Hat Display Bold';
    font-size: 15px;
    color: #ffffff;
  }
  button {
    background-color: #5d5d5d;
    width: 100%;
    font-family: 'Red Hat Display Bold';
    font-size: 14px;
    border-radius: 4px;
    text-transform: capitalize;

    &:hover {
      background-color: #5d5d5d;
    }
  }

  .MuiInputBase-root {
    width: 100% !important;
    background: #ffffff;
    height: 32px;
    margin: 10px 0;
    font-family: 'Red Hat Display SemiBold';
    font-size: 14px;
  }
}

.default-accordin .MuiAccordion-root::before {
  border-left: 0px solid !important;
}

.default-accordin .MuiAccordion-root:first-child:before {
  border-left: 0px solid !important;
}

.default-accordin .accordin-details::before {
  display: none !important;
}

.default-accordin .Mui-expanded:before {
  border-left: 0px solid !important;
}
.edit-text {
  color: #979598 !important;
  text-decoration: underline solid #979598;
  font-family: 'Red Hat Display SemiBold !important';
  font-size: 14px !important;
  line-height: 18px !important;
  cursor: pointer;
}
.common-accordin {
  .expand-new {
    -webkit-transform: rotate(180deg) !important;
    transform: rotate(180deg) !important;
  }

  .h2 {
    font-family: 'Red Hat Display Bold';
    font-size: 15px;
    color: #292929;
    text-transform: uppercase;
    line-height: 30px;
    letter-spacing: 1.88px;
    position: relative;
    padding-left: 20px;
    margin: 10px 20px;
    &::before {
      content: '';
      width: 10px;
      height: 10px;
      background: #292929;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
  }

  .MuiAccordion-root {
    margin: 20px 0px;
    border-radius: 10px 10px !important;
    box-shadow: 2px 0px 8px rgb(0 0 0 / 4%);
    padding: 0 15px;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      border-left: 2px solid #d1cbcb;
      background: transparent;
      left: -28px;
      top: 55px;
    }

    &:first-child:before {
      display: block !important;
      opacity: 1 !important;
      z-index: -1;
    }

    &:last-child:before {
      display: none;
    }

    .MuiAccordionSummary-root {
      height: 90px;
    }
  }

  .Mui-expanded {
    &:before {
      display: block !important;
      opacity: 1 !important;
    }

    &:last-child:before {
      display: none !important;
    }
  }

  .MuiPaper-root-MuiAccordion-root.Mui-expanded {
    margin: 0px 0;
  }

  .MuiPaper-root-MuiAccordion-root.Mui-expanded {
    margin: 20px !important;
  }

  .MuiAccordionSummary-expandIconWrapper {
    margin-left: 25px;
  }

  h2 {
    font-family: 'Red Hat Display Bold';
    font-size: 15px;
    color: #292929;
    text-transform: uppercase;
    line-height: 30px;
    letter-spacing: 1.88px;
    position: relative;
    padding-left: 20px;
    margin: 10px 20px;
    &::before {
      content: '';
      width: 10px;
      height: 10px;
      background: #005ebf;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
  }

  .accordin-details {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    color: #241a2e;
    padding: 10px 0;

    &::before {
      content: url(../assets/images/status-paid.svg);
      position: absolute;
      left: -57px;
    }

    p {
      font-family: 'Red Hat Display SemiBold';
      font-size: 18px;
      color: #241a2e;
      strong {
        font-family: 'Red Hat Display Bold';
        font-size: 18px;
      }
    }

    button {
      background: #bf0000;
      font-family: 'Red Hat Display Bold';
      height: 44px;
      border-radius: 2px;
      padding: 0px 20px;
      margin: 0 0px 0 25px;
      font-size: 15px;
      text-transform: capitalize;
    }
  }

  .finalize-approve {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding-bottom: 20px;

    button {
      background: #bf0000;
      font-family: 'Red Hat Display Bold';
      height: 44px;
      border-radius: 2px;
      padding: 0px 20px;
      margin: 0 0px 0 25px;
      font-size: 15px;
      text-transform: capitalize;
    }
    a {
      color: #bf0000;
      font-family: 'Red Hat Display Bold';
      margin: 0 20px;
      font-size: 16px;
    }
  }

  .accord-active {
    margin-right: 0 !important;
    a {
      color: #bf0000;
      font-family: 'Red Hat Display Bold';
      margin: 0 20px;
      font-size: 16px;
    }
  }
}

.payroll-box {
  .MuiAccordion-root {
    margin: 20px 0px;
    border-radius: 10px 10px !important;
    box-shadow: 2px 0px 8px rgb(0 0 0 / 4%);
    padding: 0 0px !important;
    position: relative;

    &::before {
      display: none !important;
    }
  }
}

.confirmation-widget {
  min-height: 165px;
  border-radius: 2px !important;

  h2 {
    font-size: 25px;
    font-family: 'Red Hat Display Bold';
    margin-bottom: 25px;
    text-align: center;
  }
  button {
    margin: 0 15px;
  }
  .confirm-btn {
    justify-content: center;
  }
  .MuiButton-outlined {
    border: 1px solid #e0e0e0;
    color: #292929;
    border-radius: 2px !important;
  }
}

.MuiFormHelperText-sizeMedium {
  font-family: 'Red Hat Display SemiBold' !important;
  font-size: 13px !important;
  margin-left: 0px !important;
}

.accordin-details1 {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  color: #241a2e;
  padding: 10px 0;

  &::before {
    content: url(../assets/images/status-paid.svg);
    position: absolute;
    left: -55px;
    top: 25px;
  }

  p {
    font-family: 'Red Hat Display SemiBold';
    font-size: 18px;
    color: #241a2e;
    strong {
      font-family: 'Red Hat Display Bold';
      font-size: 18px;
    }
  }

  button {
    background: #bf0000;
    font-family: 'Red Hat Display Bold';
    height: 44px;
    border-radius: 2px;
    padding: 0px 20px;
    margin: 0 0px 0 25px;
    font-size: 15px;
    text-transform: capitalize;
  }
  button:hover {
    background: #bf0000;
  }
}

.accordin-details-pending {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  color: #241a2e;
  padding: 10px 0;

  &::before {
    content: url(../assets/images/status-week.svg);
    position: absolute;
    left: -43px;
    top: 25px;
  }

  p {
    font-family: 'Red Hat Display SemiBold';
    font-size: 18px;
    color: #241a2e;
    strong {
      font-family: 'Red Hat Display Bold';
      font-size: 18px;
    }
  }

  button {
    background: #bf0000;
    font-family: 'Red Hat Display Bold';
    height: 44px;
    border-radius: 2px;
    padding: 0px 20px;
    margin: 0 0px 0 25px;
    font-size: 15px;
    text-transform: capitalize;
  }
  button:hover {
    background: #bf0000;
  }
}

.errorText {
  color: #da5a30 !important;
  font-family: 'Red Hat Display SemiBold' !important;
  font-size: 13px !important;
}

.flex-end-content {
  justify-content: flex-end !important;
}

.MuiTablePagination-selectLabel {
  display: none !important;
}

.react-tel-input .form-control {
  padding-left: 47px !important;
  width: 100% !important;
}
.react-tel-input .flag-dropdown {
  border: 0px solid #cacaca !important;
}
.MuiInputBase-formControl .Mui-disabled {
  color: #aeaeae !important;
  -webkit-text-fill-color: #aeaeae !important;
}

.common-table {
  thead {
    th {
      color: #979598;
      padding: 0;
      font-size: 12px;
      font-family: Red Hat Display Regular;
      line-height: unset !important;
      span {
        margin: 2px 10px;
      }
    }
  }

  tbody {
    tr {
      border-bottom: 2px solid #eee;
    }

    th {
      padding-left: 0px;
      padding-right: 0px;
    }

    th,
    td {
      color: #292929;
      font-size: 13px;
      font-style: normal;
      font-family: Red Hat Display Medium;
      padding-left: 0px;
      padding-right: 0px;

      strong {
        color: #db2426;
        font-family: Red Hat Display Bold;
      }
    }
  }
}

/*-------------------------Consultantstyles---------------------*/

.Consultant-header {
  background: #fff;
  min-height: 112px;
  margin: 0px;
  img {
    width: 176px;
  }

  ul {
    display: flex;
  }

  li {
    list-style: none;
    text-transform: uppercase;
    font-size: 16px;
    font-family: 'Red Hat Display bold';
    position: relative;
    margin: 0 10px;
    line-height: 21px;
    letter-spacing: 2px;

    a {
      color: #241a2e !important;
      text-decoration: none;
    }
  }

  .active a {
    color: #bf0000 !important;

    &:after {
      content: '';
      position: absolute;
      border-bottom: 6px solid #bf0000;
      width: 100%;
      bottom: -42px;
      left: 0;
    }
  }
  .Header-notification {
    background: #f8f8fa;
    padding: 42px 35px !important;

    img {
      width: 25px;
    }
  }

  .request-payment {
    color: #292929;
    font-family: 'Red Hat Display Medium' !important;
    text-align: center;
    h6 {
      font-size: 13px;
      font-family: 'Red Hat Display Medium';
    }
    h5 {
      font-size: 25px;
      font-family: 'Red Hat Display Medium';
    }
  }

  button {
    border: 1px solid #bf0000;
    font-size: 12px;
    font-family: 'Red Hat Display Bold';
    color: #bf0000;
    text-transform: capitalize;

    &:hover {
      border: 1px solid #bf0000;
      background: transparent;
    }
  }

  .notify-bg {
    display: flex;
    align-items: center;
    background: #f8f8fa;

    P {
      padding: 12px 35px;
      border-right: 1px solid #fff;
      position: relative;

      &:after {
        content: '';
        position: absolute;
        border-right: 2px solid #ffffff;
        top: 0;
        right: 0;
        height: 100%;
      }
    }
  }
}

.container-block {
  width: 98%;
  margin: 30px auto !important;
}

.Adjustment {
  width: 94%;
}

.status-details {
  margin-bottom: 10px !important;
}

.expand-new {
  -webkit-transform: rotate(180deg) !important;
  transform: rotate(180deg) !important;
}
.sigCanvas {
  margin: 0 auto;
  display: table;
  background: #eeeef1;
}

/* Pay sheet box design start */

.paysheet-h-overflow {
  width: 100%;
  display: flex;
  flex-direction: column !important;
  justify-content: flex-start;
}
.paysheet-tables-overflow {
  height: 632px;
  max-height: 632px;
  overflow: scroll;
  border-bottom: 1px solid #e0e0e0;
}

.paysheet-tdbles-left-amount-lbl {
  color: #292929 !important;
  font-family: 'Red Hat Display Bold' !important;
  font-size: 14px !important;
  letter-spacing: 0 !important;
  line-height: 24px !important;
  text-align: left;
}

.paysheet-tables-ytd-overflow {
  height: 613px;
  max-height: 613px;
  overflow: scroll;
  padding-left: 16px !important;
  border-bottom: 1px solid #e0e0e0;
}

.paysheet-total-amt-box {
  width: 100%;
  height: 41px;
  margin-top: 10px;
  margin-bottom: 12px;
  display: flex;
  flex-direction: row;
}
.paysheet-total-fn {
  font-size: 20px !important;
  line-height: 26px !important;
  margin-left: 36px !important;
  margin-right: 15px !important;
  width: 220px !important;
  color: #292929 !important;
  font-family: 'Red Hat Display Bold' !important;
  letter-spacing: 0 !important;
  align-self: center;
}
.paysheet-total-val-fn {
  font-size: 24px !important;
  line-height: 41px !important;
  color: #1d9900 !important;
  font-family: 'Red Hat Display Bold' !important;
  letter-spacing: 0;
}
.paysheet-deductions-fn {
  color: #bf0000 !important;
  font-family: 'Red Hat Display Bold' !important;
  font-size: 14px !important;
  letter-spacing: 0 !important;
  line-height: 23px !important;
}
.paysheet-remain-fn {
  height: 23px;
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 4px;
}
.paysheet-total-label {
  color: #292929 !important;
  font-family: 'Red Hat Display SemiBold' !important;
  font-size: 18px !important;
  letter-spacing: 0 !important;
  line-height: 23px !important;
  width: 220px !important;
  margin-right: 15px !important;
  margin-left: 36px !important;
}
.paysheet-total-value {
  color: #292929 !important;
  font-family: 'Red Hat Display Bold' !important;
  font-size: 18px !important;
  letter-spacing: 0 !important;
  line-height: 23px !important;
}

.paysheet-deduct-expense-box {
  width: calc(100% - 40px);
  height: 54px;
  margin-left: 40px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.payment-deduct-expense-item {
  height: 100%;
  display: flex;
  flex-direction: row;
  border-radius: 10px;
  background-color: #f8f8fa;
  margin-right: 8px;
}

.PaysheetNotesLayout {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.PaysheetNotesText {
  color: #292929 !important;
  font-family: 'Red Hat Display SemiBold' !important;
  font-size: 14px !important;
  letter-spacing: 0 !important;
  line-height: 41px !important;
}

.paysheet-ytd-box {
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  box-sizing: border-box;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 10px;
}

.paysheet-ytd-header {
  color: #292929 !important;
  font-family: 'Red Hat Display Bold' !important;
  font-size: 16px !important;
  letter-spacing: 0 !important;
  line-height: 21px !important;
  margin-left: 20px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.paysheet-ytd-header-2-outline {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.paysheet-ytd-header-2 {
  color: #292929 !important;
  font-family: 'Red Hat Display SemiBold' !important;
  font-size: 15px !important;
  letter-spacing: 0 !important;
  line-height: 20px !important;
  margin-left: 20px !important;
}

.paysheet-ytd-table-plist-outer:hover {
  background-color: rgba(0, 94, 191, 0.06);
}
.paysheet-ytd-table {
  width: 'calc(100% - 40px)';
  margin-left: 20px;
  margin-top: 14px;
}
.paysheet-ytd-table-row {
  height: 24px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.paysheet-ytd-table-col-1 {
  width: 126px;
  height: 100%;
}

.paysheet-ytd-table-date {
  color: #292929 !important;
  font-family: 'Red Hat Display SemiBold' !important;
  font-size: 14px !important;
  letter-spacing: 0 !important;
  line-height: 24px !important;
  max-width: 254px !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
.paysheet-ytd-table-amount {
  color: #292929 !important;
  font-family: 'Red Hat Display Bold' !important;
  font-size: 14px !important;
  letter-spacing: 0 !important;
  line-height: 24px !important;
  text-align: right;
}
.overall-total-fn {
  color: #292929 !important;
  font-family: 'Red Hat Display Bold' !important;
  font-size: 18px !important;
  letter-spacing: 0 !important;
  line-height: 32px !important;
}
.overall-total-sal {
  color: #292929 !important;
  font-family: 'Red Hat Display SemiBold' !important;
  font-size: 18px !important;
  letter-spacing: 0 !important;
  line-height: 32px !important;
}
.paysheetIcon {
  height: 20px;
  width: 20px;
  margin-left: 4px;
  cursor: pointer;
}
.paysheet-ytd-billed-lable {
  font-size: 12px !important;
  font-family: 'Red Hat Display SemiBold' !important;
  color: #979598 !important;
  margin-bottom: 5px !important;
  padding-left: 16px !important;
}
.paysheet-table-expand-lable {
  color: #005ebf !important;
  font-family: 'Red Hat Display Bold' !important;
  font-size: 14px !important;
  letter-spacing: 0 !important;
  line-height: 20px !important;
  margin-left: 20px !important;
  text-decoration: underline;
  cursor: pointer;
  width: 100px;
  height: 38px;
  align-items: center !important;
  display: flex;
}
/* Pay sheet box design end */

.notices-details {
  display: flex;
  P {
    font-family: 'Red Hat Display Medium' !important;
    font-size: 13px !important;
    color: #979598 !important;
    margin: 10px 0px !important;
    line-height: 17px;
    a {
      color: #979598;
    }
  }
}

.timesheet-details {
  border: 1px solid #e0e0e0;
  min-height: 200px;
  border-radius: 10px;
  padding: 20px;
  p {
    font-family: 'Red Hat Display bold';
    font-size: 16px;
    color: #241a2e;

    em {
      font-family: 'Red Hat Display SemiBold';
      font-style: normal;
    }

    thead {
      th {
        font-family: 'Red Hat Display SemiBold';
        font-size: 13px;
        color: #292929;
        padding: 0 5px 10px;
      }

      .hours-timesheet {
        font-family: 'Red Hat Display SemiBold' !important;
        font-size: 16px !important;

        span {
          font-family: 'Red Hat Display Bold';
        }
      }
    }

    tbody {
      tr {
        border-bottom: 4px solid #fff;
      }
      th,
      td {
        font-family: 'Red Hat Display Bold';
        color: #292929;
        font-size: 16px;
        padding: 5px 15px;
        position: relative;

        a {
          color: #005ebf;
          padding-right: 8px;
          font-family: 'Red Hat Display Bold';
          text-decoration: underline;
          line-height: 22px;
          display: inline-block;
        }
        input {
          width: 100px;
          margin: 0 auto;
          text-align: center;
          height: 40px !important;
          background: #ffffff;
          border-radius: 4px;
          border: 1px solid #e0e0e0 !important;
          font-family: 'Red Hat Display Bold' !important;
          font-size: 16px !important;
          color: #241a2e !important;
        }
      }

      .active-blue {
        background: rgba(0, 94, 191, 0.06);
        position: relative;
        border-left: 4px solid #005ebf;
        border-bottom: 4px solid #fff;
      }

      .active-gray {
        background: #f3f3f3;
        position: relative;
        border-left: 4px solid #d1d1d1;
        border-bottom: 4px solid #fff;

        th {
          color: #979598;
        }

        img {
          position: relative;
          top: 3px;
          left: -5px;
        }
      }
      .active-yellow {
        background: rgba(191, 107, 0, 0.1);
        border-left: 4px solid #e86b07;
        border-bottom: 4px solid #fff;

        td {
          a {
            color: #9a7878 !important;
          }

          img {
            position: relative;
            top: 3px;
            left: -5px;
          }
        }
      }
    }
  }
  .notices {
    a {
      font-family: 'Red Hat Display Bold';
      font-size: 12px;
      color: #979598;
      float: left;
      margin: 10px 20px 0px 0px;
    }
  }
}
table {
  th {
    line-height: unset !important;
  }
}
.timesheet-table {
  border-bottom: 1px solid #e0e0e0;
  padding: 15px 0 10px;
  p {
    font-family: Red Hat Display SemiBold;
    font-size: 14px;
    color: #292929;
    strong {
      font-family: 'Red Hat Display Bold';
    }
  }

  table {
    thead {
      th {
        font-family: 'Red Hat Display Bold';
        font-size: 14px;
        color: #292929;
        padding: 5px 0;
        line-height: unset !important;
      }
    }

    tbody {
      th,
      td {
        font-family: 'Red Hat Display SemiBold';
        font-size: 14px;
        color: #292929;
        padding: 5px 0;
      }
      a {
        color: #005ebf;
        font-size: 14px;
        font-family: 'Red Hat Display Bold';
      }
    }
  }
}

.model-timesheet {
  p {
    font-family: 'Red Hat Display SemiBold';
    display: inline;
    font-size: 18px;
    color: #292929;
    span {
      font-size: 13px;
      font-family: 'Red Hat Display SemiBold';
    }
    strong {
      font-family: Red Hat Display Bold;
    }
  }
}

.paymentbox {
  height: 500px;
  overflow-y: scroll;

  h6 {
    font-size: 12px;
    font-family: 'Red Hat Display SemiBold';
    color: #979598;
    margin-bottom: 5px;
  }
}

.otherpay {
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  padding: 20px;

  .billing-details {
    P {
      font-family: 'Red Hat Display SemiBold';
      font-size: 15px;

      a {
        font-family: 'Red Hat Display Bold';
        color: #005ebf;
        font-size: 12px;
      }
    }
    h4 {
      font-family: 'Red Hat Display Bold';
      font-size: 16px;
      color: #292929;
      line-height: 22px;
    }
  }

  .bill-payments {
    display: flex;
    border-bottom: 1px solid #e8e8e8;
    padding: 6px 0px;
    p:first-child {
      font-family: Red Hat Display SemiBold;
    }
    p {
      flex-basis: 33.3%;
      font-family: Red Hat Display Bold;
      font-size: 14px;
      padding: 4px 0;

      strong {
        font-family: 'Red Hat Display Bold';
      }
    }
  }
  .bill-payments1 {
    display: flex;
    border-bottom: 1px solid #e8e8e8;
    padding: 6px 0px;
    p:first-child {
      font-family: Red Hat Display SemiBold;
    }
    p {
      flex-basis: 50%;
      font-family: Red Hat Display Bold;
      font-size: 14px;
      padding: 4px 0;

      strong {
        font-family: 'Red Hat Display Bold';
      }
    }
  }
}

.paysheet-table {
  thead {
    tr {
      border-bottom: 2px solid #eee;
    }
    th {
      color: #979598;
      padding: 0;
      font-size: 12px;
      font-family: Red Hat Display Regular;
      padding: 5px 8px;
    }
  }
  tbody {
    // tr {
    //   &:nth-child(2) {
    //     background-color: rgba(0, 94, 191, 6%);
    //   }
    // }
    .showView {
      display: none;
    }
    tr:hover {
      background-color: rgba(0, 94, 191, 6%);
      .showView {
        display: block;
      }
    }
    td,
    th {
      color: #292929;
      font-size: 16px;
      font-style: normal;
      font-family: 'Red Hat Display SemiBold';
      padding: 12px 15px;

      a {
        color: #005ebf;
        font-size: 12px;
        font-family: 'Red Hat Display Bold';
      }
    }
  }
}

.paysheet-title {
  margin-bottom: 25px;
  p {
    font-family: 'Red Hat Display SemiBold';
    display: inline-block;
    margin-right: 35px;
    font-size: 18px;
    strong {
      font-family: 'Red Hat Display Bold';
    }
    a {
      color: #292929;
      font-family: 'Red Hat Display Bold';
    }
  }
}

.paysheet-total {
  h4 {
    font-family: 'Red Hat Display Bold';
    font-size: 20px;
    margin: 25px 0;
    padding: 0px 15px;
  }
}

.paysheet-list {
  background: #f8f8fa !important;
  min-height: 54px;
  width: 100%;
  padding: 0 20px !important;
  border-radius: 10px;
  justify-content: space-between !important;
  P {
    font-family: 'Red Hat Display SemiBold';
    font-size: 16px;
    color: #979598;
    margin-right: 10px;
  }
  strong {
    font-family: 'Red Hat Display Bold';
    font-weight: bold;
    color: #979598;
  }
  .deduction {
    display: flex;
    align-items: center;
  }
  p:last-child {
    display: flex;
    align-items: center;
  }
}

.otherpay {
  margin: 10px 0 !important;
  h3 {
    font-family: 'Red Hat Display Bold';
    text-transform: uppercase;
    font-size: 20px;
    letter-spacing: 2px;
  }
}

.request-payment-alert {
  padding: 30px !important;
  border-radius: 10px;
  box-shadow: 1px 5px 5px rgb(0 0 0 / 4%);
  background: #ffffff;
  margin: 25px 0 !important;

  .total-payment {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
    border-bottom: 1px solid #e8e8e8;
    padding-bottom: 12px;

    p {
      font-family: 'Red Hat Display SemiBold';
      font-size: 20px;
      color: #292929;
      strong {
        color: #bf0000;
        font-family: 'Red Hat Display Bold';
      }
    }
  }

  .total-payment-option {
    display: flex;
    justify-content: space-between;
    span {
      font-family: Red Hat Display SemiBold;
      font-size: 16px;
      color: #241a2e;
    }
    input {
      width: 110px;
      height: 40px !important;
      border: 1px solid #d5d5d5 !important;
      border-radius: 4px !important;
      text-align: center;
      font-family: 'Red Hat Display Bold' !important;
      font-size: 16px !important;
    }
  }

  .paymentrequest-table {
    margin-top: 15px;
    table {
      thead {
        th {
          color: #bf0000;
          font-family: 'Red Hat Display SemiBold';
          font-size: 12px;
          padding: 0px 0px;
        }
      }

      tbody {
        th,
        td {
          font-family: 'Red Hat Display Bold';
          font-size: 16px;
          color: #292929;
          padding: 0px 0px;

          span {
            font-size: 16px;
            position: relative;
            left: 23px;
            font-family: 'Red Hat Display Bold';
          }
        }
        input {
          width: 110px;
          height: 32px !important;
          border: 1px solid #d5d5d5 !important;
          border-radius: 4px !important;
          text-align: center;
          font-family: 'Red Hat Display Bold' !important;
          font-size: 16px !important;
        }
      }
    }
  }
}

.tableFooterlabel {
  font-family: 'Red Hat Display SemiBold' !important;
  font-size: 14px !important;
  color: #292929 !important;
  margin: 0px !important;
}

.tableFooterlabel1 {
  font-family: 'Red Hat Display Bold' !important;
  font-size: 14px !important;
  color: #292929 !important;
  margin: 0px !important;
}

._loading_overlay_overlay {
  position: fixed !important;
  z-index: 10000 !important;
}
._loading_overlay_wrapper {
  width: 100% !important;
}
._version_bold_text {
  font-family: Red Hat Display Bold !important;
  font-size: 15px;
  color: #292929;
  text-transform: uppercase;
  line-height: 30px;
  letter-spacing: 1.88px;
  position: relative;
  font-weight: bold !important;
}
._version_semi-bold_text {
  font-family: Red Hat Display SemiBold !important;
  color: #292929;
  line-height: 30px;
  letter-spacing: 1.88px;
  position: relative;
  font-weight: bold !important;
}
._version_semi-bold_head_text {
  font-family: Red Hat Display SemiBold !important;
  color: #292929 !important;
  line-height: 30px !important;
  letter-spacing: 1.2px !important;
  position: relative !important;
  font-weight: bold !important;
}
.cke_contents {
  height: 300px !important;
}
.orangeButton {
  background: #bf0000 !important;
  font-family: 'Red Hat Display Bold' !important;
  height: 34px !important;
  border-radius: 2px !important;
  margin: 0 0px 0 25px !important;
  font-size: 15px !important;
  text-transform: capitalize !important;
}
.orangeButton:hover {
  background: #bf0000 !important;
}

.whiteButton {
  background: #ffffff !important;
  font-family: 'Red Hat Display Bold' !important;
  height: 34px !important;
  border-radius: 2px !important;
  margin: 0 0px 0 25px !important;
  font-size: 15px !important;
  text-transform: capitalize !important;
  color: #005ebf !important;
}
.whiteButton:hover {
  background: #ffffff !important;
}
/*---------------------------Device responsive styles----------------------*/

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 767px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
@media only screen and (max-width: 899px) {
  .headernav {
    font-size: 11px !important;
  }
  .filterlist {
    button {
      font-size: 11px !important;
    }
  }
}
@media (max-width: 1580px) {
  .custom-search input {
    width: 100% !important;
  }
}
@media only screen and (max-width: 1400px) {
  .timesheet-details input {
    width: 85% !important;
  }
  .timesheet-details tbody th {
    width: 30%;
  }
  .timesheet-details-admin tbody th {
    width: 38%;
  }
  .timesheet-details-admin input {
    width: 100% !important;
  }
}
@media (min-height: 600px) {
  .login-area {
    position: absolute;
  }
}
.MuiDayPicker-weekContainer .Mui-disabled {
  color: grey !important;
}
.pg-viewer-wrapper {
  overflow-y: hidden !important;
}
.common-textarea-timereq {
  background: #f8f8fa;
  width: 100%;
  margin-bottom: 25px;
  border: 1px solid #d5d5d5;
  border-radius: 8px;
  border-bottom: 1px solid #e8e8e8;
  .MuiInputBase-multiline {
    padding: 0;
  }
  textarea {
    font-family: 'Red Hat Display SemiBold' !important;
    font-size: 14px !important;
    color: black !important;
    height: 120px !important;
    border: 0px solid transparent !important;
    margin: 0 !important;
  }
}

.width-100 {
  width: 100% !important;
}
.width-95 {
  width: 95% !important;
}
.currencyLabel {
  background-color: inherit !important;
  height: inherit !important;
  padding: 0 !important;
  margin: 0 !important;
  color: #292929 !important;
  font-size: 14px !important;
  font-family: 'Red Hat Display Bold' !important;
  border: 0px !important;
  width: 110px !important;
  border-radius: 0px !important;
}
.currencyLabel:hover {
  border: 0px !important;
}
.myLabelEditTableClass {
  color: #292929 !important;
  font-size: 13px !important;
  font-style: normal !important;
  display: grid !important;
  font-family: 'Red Hat Display Medium' !important;
  cursor: pointer !important;
}
.myInputEditTableClass {
  color: #292929 !important;
  font-size: 13px !important;
  font-style: normal !important;
  font-family: 'Red Hat Display Medium' !important;
  border: 0px !important;
  // background-color: #f7f7f7 !important;
  background-color: transparent !important;
  cursor: pointer !important;
  width: inherit;
  height: 30px !important;
}
.myInputEditTableClass:hover {
  background-color: #f7f7f7 !important;
}
.myInputEditTableClass:focus-visible {
  background-color: #f7f7f7 !important;
}
.myLabelEditTableClass1 {
  color: white !important;
  font-size: 13px !important;
  font-style: normal !important;
  display: grid !important;
  font-family: 'Red Hat Display Medium' !important;
  cursor: pointer !important;
}
.inn-text {
  .arrow-top-left {
    position: absolute;
    top: -11px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 12px solid rgba(0, 0, 0, 0.84);
    left: 8px;
    z-index: 1;
  }
  p {
    color: #ffffff !important;
    font-family: 'Red Hat Display Regular' !important;
    font-size: 12px !important;
    line-height: 19px;
    cursor: pointer;
  }
}
.shadow-popover-wa {
  .MuiPopover-paper {
    background-color: #292929 !important;
    min-height: 48px;
    padding: 11px 12px 11px 12px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5) !important;
    overflow: visible !important;
    z-index: 100;
    margin-top: 17px;
    border-radius: 12px !important;
  }
}

.deduction-box {
  background-color: #ffffff;
  border: 0px solid #000;
  border-radius: 10px;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
    0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  left: 50%;
  padding: 25px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 450px;
  overflow-y: auto;
  input {
    border: 0px solid #e0e0e0 !important;
  }
}

.payroll-table {
  thead {
    tr {
      border-bottom: 2px solid #eee;
    }
    th {
      min-width: 160px !important;
      color: #979598;
      padding: 0;
      font-size: 12px;
      font-family: Red Hat Display Regular;
      padding: 5px 8px;
      white-space: nowrap;
    }
  }
  tbody {
    .showView {
      display: none;
    }
    tr:hover {
      background-color: rgba(0, 94, 191, 6%);
      .showView {
        display: block;
      }
    }
    td {
      padding: 10px 12px;
      color: #292929;
      font-size: 13px;
      font-style: normal;
      font-family: Red Hat Display Medium;
      line-height: 17px;
      padding: 6px 15px;
      padding-left: 9px;
    }
    th {
      padding: 10px 12px;
      color: #292929;
      font-size: 13px;
      font-style: normal;
      font-family: Red Hat Display Medium;
      line-height: 17px;
      padding: 12px 15px;
      padding-left: 9px;
      a {
        color: #005ebf;
        font-size: 12px;
        font-family: 'Red Hat Display Bold';
      }
    }
  }
}
.payroll-textfield {
  padding: 6px 0px !important;
  & > div {
    background-color: #f8f8fa !important;
    padding: 10px 12px;
    color: #292929;
    font-size: 13px;
    font-style: normal;
    font-family: Red Hat Display Medium;
    line-height: 17px;
    // width: 420px;
    width: 100%;
  }
}
.past-payrollquestions {
  vertical-align: middle;
  display: flex !important;
  align-items: center !important;
  gap: 6px;
  // justify-content: space-between;
}
.payroll-attention {
  padding: 0px !important;
}
.past-payroll-red-card {
  background-color: rgba(191, 0, 0, 0.1);
  border-radius: 10px;
}
.past-payroll-red-card {
  display: flex;
  gap: 35px;
  padding: 10px 20px 10px 10px;
  font-size: 10px;
}
.past-payrollquestions2 {
  align-self: flex-start !important;
  color: #bf0000 !important;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 2px;
}
.past-payrolldescription2 {
  color: #292929;
  font-size: 13px;
  font-style: normal;
  font-family: Red Hat Display Medium;
  line-height: 17px;
}
.payroll-secondcontain {
  padding-bottom: 10px !important;
  padding-top: 5px !important;
  border-radius: 8px !important;
}
.phoneLabel {
  input {
    background-color: transparent !important;
    border: 1px solid transparent !important;
    padding-top: 0px !important;
    font-size: 14px !important;
    color: #292929 !important;
    font-family: Red Hat Display Bold !important;
  }
  .flag-dropdown {
    background-color: transparent !important;
    border: 1px solid transparent !important;
    padding-top: 0px !important;
  }
}
.fullViewAccordian {
  // height: 100% !important;
  // position: absolute !important;
  // width: 97% !important;
  // overflow: hidden !important;
  // z-index: 1000 !important;
  // top: 20px !important;
  height: calc(100vh - 15vh) !important;
  position: absolute !important;
  width: 98% !important;
  overflow: scroll !important;
  z-index: 1000 !important;
  top: 5px !important;
  .TableContainerPayrollSummary {
    max-height: calc(100vh - 15vh) !important;
  }
}
.hideAll {
  display: none !important;
}
